.containerCoverImage {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100vh;
  width: 100%;
  background-image: url("../../../images/plantweb.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.containerPage {
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

.menu {
  all: unset;
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  right: 0;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  z-index: 999;
  background-color: #394b41e6;

  .menu__burger {
    margin: 0;
    padding: 0;
    width: 50px;
    height: 6px;
    background-color: #59f9b4;
    position: absolute;
    border-radius: 5px;
    transition: all 0.05s ease-in-out;

    &:before {
      margin: 0;
      padding: 0;
      content: "";
      position: absolute;

      width: 50px;
      height: 6px;
      background: #59f9b4;
      border-radius: 5px;
      transition: all 0.5s ease-in-out;
      transform: translateY(-16px);
    }

    &:after {
      margin: 0;
      padding: 0;

      content: "";
      position: absolute;
      width: 50px;
      height: 6px;
      background: #59f9b4;
      border-radius: 3px;
      transition: all 0.5s ease-in-out;
      transform: translateY(16px);
      margin-bottom: 10px;
    }
  }
}

.header {
  position: relative;
  z-index: 1;

  width: 100vw;
  .nav {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #394b41f7;
    opacity: 0.9;
    width: 100%;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 998;
    .nav__list {
      margin: 0;
      height: 100%;
      list-style-type: none;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;

      .nav__item {
        font-size: 1.7rem;

        .nav__link {
          font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
            sans-serif;
          color: white;
          text-decoration: none;
        }
      }
    }
  }
}

.menu.open .menu__burger {
  transform: translateX(-50px);
  background: transparent;
  box-shadow: none;
}

.menu.open .menu__burger::before {
  transform: rotate(45deg) translate(35px, -35px);
}

.menu.open .menu__burger::after {
  transform: rotate(-45deg) translate(35px, 35px);
}

.main {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .containerCoverImage {
    background-image: url("../../../images/officePlant.jpg");
    height: 100vh;
    width: 100%;
  }

  .main {
    width: 100%;
    margin: 0;
  }

  .nav__item {
    .nav__link {
      font-size: 1.2rem;
    }
  }

  .menu {
    width: 50px;
    height: 50px;

    box-sizing: border-box;
    .menu__burger {
      width: 35px;
      height: 4px;

      &:before {
        content: "";

        width: 35px;
        height: 4px;
      }

      &:after {
        width: 35px;
        height: 4px;
      }
    }
  }

  .menu.open .menu__burger {
    transform: translateX(-50px);
    background: transparent;
    box-shadow: none;
  }

  .menu.open .menu__burger::before {
    transform: rotate(45deg) translate(35px, -35px);
  }

  .menu.open .menu__burger::after {
    transform: rotate(-45deg) translate(35px, 35px);
  }
}

.languageSwitch {
  height: 35px;
  width: 150px;
  border-radius: 10px;
  background-color: #59f9b4ae;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5px;
  margin-left: 5px;
  text-shadow: 0px 0px 15px #0000009f;
  a {
    text-decoration: none;
    color: #ffffff;
    font-size: 1.2rem;
  }
}
