.footer {
    width: 100%;
    background-color:#394B41E6;
display: flex;
justify-content: center;
align-items: center;
margin-top: 50px;
padding-top: 20px;
padding-bottom: 20px;

.footerContainer {
    padding: 20px;
    width: 80%;
    display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
    .socials{
        display: flex;

        article {
            padding: 10px;
            img {
               width: 35px;
            }
        }
    }
    
    .copyright {
 color:  rgb(89,249,180);
    }
    
    .contact {
        color: white;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
     
            a{
                padding-left: 10px;
                color: white;
            }
        
    }
    
}



}

@media (min-width:600px) and (max-width:900px) {
    .footer {
    .footerContainer {
    .contact {
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
     
            a{
                padding-left: 10px;
                color: white;
            }
        
    }}
}}

@media screen and (max-width:600px) {
    .footer {
        .footerContainer {
            flex-direction: column;
    
        .socials{
            display: flex;
    
            article {
            
              
            }
        }
        
        .copyright {
            margin-top: 10px;
            
            margin-bottom: 10px;
     color:  rgb(89,249,180);
        }
        
        .contact {
            color: white;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
         
                a{
                    padding-left: 10px;
                    color: white;
                }
            
        }
    
    }
    }

}