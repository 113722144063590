.languageContainer {
  width: 100%;
  height: 40vh;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 70px;
  margin-top: 70px;

  .languageBox {
    min-height: 20vh;
    width: 60%;
    height: fit-content;
    background-color: #394b41e6;
    border-radius: 5px;
    padding: 20px;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    padding-bottom: 40px;

    .languageHeading {
      color: #59f9b4;
      width: 50%;
      text-align: center;
    }

    .radioContainer {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: start;
      flex-direction: column;

      .languageArticle {
        margin-top: 20px;

        .languageRadioHeading {
          font-size: 1.4rem;
          font-weight: 400;
          margin: 0px;
          margin-bottom: 10px;
        }
        .inputRadio {
          display: flex;
        }
      }
    }
  }
}

input[type="radio"]:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #151515c7;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid #59f9b4;
}

input[type="radio"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #59f9b4;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

@media (min-width: 600px) and (max-width: 900px) {
  .languageContainer {
    .languageBox {
      width: 80%;
      .languageHeading {
        font-size: 1.1rem;
      }
    }
  }
}

@media screen and (max-width: 599px) {
  .languageContainer {
    margin-bottom: 10vh;
    .languageBox {
      width: 90%;
      flex-direction: column;

      .languageHeading {
        width: 100%;
        text-align: center;
        font-size: 1.2rem;
      }

      .radioContainer {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .languageArticle {
          margin-top: 20px;

          .languageRadioHeading {
            font-size: 0.9rem;
          }
        }
      }
    }
  }
}
