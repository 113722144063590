.backgroundContainer {
  margin-top: 100vh;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 50px;

  .backgroundArticleLeft {
    width: 60%;
    min-height: 400px;
    height: fit-content;
    background-color: #394b41e6;
    border-radius: 5px;
    padding: 20px;
    #skillsHeading {
      color: #59f9b4;
      font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
        sans-serif;
    }
    .scaleOptionsContainer {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .scaleContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 80%;

        p {
          margin: 0;
          padding: 0;
          margin-bottom: -20px;
          margin-top: 20px;
          color: white;
          width: 33%;
          padding-left: 10px;
        }
      }
    }
  }
}

.skillsBarContainer1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  padding: 0;

  .skillsBarContainer2 {
    width: 90%;
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;

    h3 {
      width: 10%;
      font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
        sans-serif;
      font-weight: 300;
      font-style: italic;
      color: white;
      padding-right: 20px;
    }
  }
}

.progress {
  width: 80%;
  height: 18px;
  background: white;
  background: linear-gradient(
    180deg,
    rgba(134, 118, 118, 1) 0%,
    rgba(88, 60, 60, 1) 47%,
    rgba(134, 118, 118, 1) 75%
  );
  border-radius: 10px;
  box-shadow: 2px 2px 5px black;

  .progress-bar {
    box-shadow: 2px 2px 5px white;
    width: 0%;
    height: 100%;
    background: rgb(89, 249, 180);
    background: linear-gradient(
      180deg,
      rgba(89, 249, 180, 1) 0%,
      rgba(255, 255, 255, 1) 50%,
      rgba(89, 249, 180, 1) 100%
    );
    opacity: 1;
    border-radius: 10px;
    position: relative;
    span {
      position: absolute;
      font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
        sans-serif;
      top: 0;
      right: 0;
      color: black;

      padding-right: 10px;
    }
  }
}

@media (min-width: 600px) and (max-width: 900px) {
  .backgroundContainer {
    .backgroundArticleLeft {
      width: 80%;
      box-sizing: border-box;

      #skillsHeading {
      }
      .scaleOptionsContainer {
        .scaleContainer {
          p {
          }
        }
      }
    }
  }

  .skillsBarContainer1 {
    .skillsBarContainer2 {
      h3 {
      }
    }
  }

  .progress {
    .progress-bar {
      span {
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .backgroundContainer {
    margin-top: 110vh;
    .backgroundArticleLeft {
      padding: 10px;
      width: 90%;
      box-sizing: border-box;

      #skillsHeading {
        font-size: 1.1rem;
      }
      .scaleOptionsContainer {
        padding-left: 23%;

        .scaleContainer {
          width: 100%;
          justify-content: space;

          p {
            font-size: 0.8rem;
            width: 33%;
          }
        }
      }
    }
  }

  .skillsBarContainer1 {
    .skillsBarContainer2 {
      justify-content: space-evenly;
      width: 100%;

      h3 {
        font-size: 0.8rem;
      }
    }
  }

  .progress {
    width: 70%;

    .progress-bar {
      span {
      }
    }
  }
}
