.containerCover {
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  .containerPresentation {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    .containerH1 {
      background-color: #394b41e6;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      width: 57%;
      border-radius: 5px;
      padding: 30px;

      .containerPresText {
        h1 {
          display: block;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          width: 100%;
          text-align: center;
          margin: 0;
          margin-top: 20px;
          padding-left: 20px;
          padding-right: 20px;

          font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
            sans-serif;
          color: #59f9b4;
          font-size: 2rem;
          line-height: 45px;
          text-shadow: 0px 0px 30px white;
        }

        .divider {
          width: 100%;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          div {
            width: 40%;
            height: 2px;
            background-color: white;
            border-radius: 5px;
          }
        }
        .interestContainer {
          width: 100%;
          display: flex;
          justify-content: space-evenly;
          height: 70px;
          .iconImageContainer {
            position: relative;
            margin: 0;
            padding: 0;
            width: 80px;
            height: 70px;
            z-index: 99;

            #iconCar {
              width: 100px;
            }
            &:hover .intrestIcon {
              display: none;
            }

            &:hover + .iconText {
              display: block;
              color: #59f9b4;
            }

            .intrestIcon {
              position: relative;
              margin: 0;
              padding: 0;
              width: 70px;
              height: 70px;
            }
          }
          .iconText {
            position: relative;
            margin-top: -60px;
            display: none;
            color: white;
            width: 70px;
            height: 90px;
            font-size: 0.9rem;
            text-align: center;
          }
        }
      }
      .imageContainer {
        margin: 0;
        height: 300px;
        width: auto;
        margin-right: 20px;

        .imgCoverDesktop {
          height: 300px;
          width: auto;
          opacity: 0.87;
          border-radius: 5%;
          display: block;
        }
        .imgCoverTablet {
          display: none;
          height: 300px;
          width: auto;
          opacity: 0.87;
          border-radius: 5%;
        }
      }
    }
  }
}

#iconCar {
  width: 90px;
}

.buttonMailContainer {
  text-align: center;
  button {
    margin-bottom: 20px;
    padding: 10px 20px 10px 20px;
    border-radius: 15px;
    background-color: white;
    border: none;
    color: #394b41;
    font-size: 1.2rem;
    cursor: pointer;
    &:hover {
      background-color: #59f9b4;
    }
  }
}

@media (min-width: 600px) and (max-width: 900px) {
  .containerCover {
    .containerPresentation {
      flex-direction: column-reverse;
      justify-content: space-evenly;

      .containerH1 {
        width: 80%;
        padding-bottom: 20px;

        h1 {
          font-size: 1.4rem;
        }

        .divider {
          div {
          }
        }
        .interestContainer {
          pointer-events: none;
          .iconImageContainer {
            &:hover .intrestIcon {
              display: none;
            }

            &:hover + .iconText {
              display: none;
            }

            .intrestIcon {
              width: 50px;
              height: 50px;
            }
          }
          .iconText {
            display: none;
          }
        }
      }
      .imageContainer {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 80%;

        .imgCoverDesktop {
          display: none;
        }
        .imgCoverTablet {
          display: block;
          width: 50%;
          height: auto;
        }
      }
    }
  }

  .buttonMailContainer {
    button {
    }
  }
}

@media screen and (max-width: 599px) {
  .containerCover {
    min-height: 110vh;
    height: fit-content;
    align-items: center;
    .containerPresentation {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      margin: 0;
      width: 100%;
      height: fit-content;
      .containerH1 {
        min-height: 30vh;
        height: 80vh;
        width: 90%;
        padding-bottom: 10px;
        margin-bottom: 40px;
        flex-direction: column-reverse;
        justify-content: space-around;
        .containerPresText {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          h1 {
            font-size: 1.1rem;
            line-height: 25px;
            padding: 0px;
          }

          .divider {
            height: 20px;
            div {
            }
          }
          .interestContainer {
            height: 50px;
            pointer-events: none;
            article {
              height: 50px;
              display: flex;
              justify-content: center;
              align-items: center;

              .iconImageContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 35px;
                height: 35%;

                &:hover .intrestIcon {
                  display: none;
                }

                &:hover + .iconText {
                  display: none;
                }

                .intrestIcon {
                  width: 50px;
                  height: 50px;
                }

                #iconCar {
                  width: 50px;
                }
              }
              .iconText {
                display: none;
              }
            }
          }
        }
        .imageContainer {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0px;

          .imgCoverDesktop {
            display: none;
          }
          .imgCoverTablet {
            display: block;
            width: 80%;
            height: auto;
          }
        }
      }
    }
  }

  .buttonMailContainer {
    button {
      margin-top: 10px;
      font-size: 0.8rem;
    }
  }
}
