.notfoundContainer {
  width: 100%;
  height: 100vh;
  background: #59f9b4;
  background: -webkit-linear-gradient(top left, #59f9b4, #394b41);
  background: -moz-linear-gradient(top left, #59f9b4, #394b41);
  background: linear-gradient(to bottom right, #59f9b4, #394b41);
  display: flex;
  justify-content: center;
  align-items: center;

  .notfoundBox {
    height: 40%;
    width: 50%;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.362);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    a {
      background-color: #394b41bf;
      padding: 20px 30px 20px 30px;
      border-radius: 10px;
      box-shadow: 3px 3px 5px #0000006c;
      color: white;
      text-decoration: none;
    }
  }
}
