.workexperienceContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;

  .workexperience {
    height: 100%;
    width: 60%;
    height: fit-content;
    background-color: #394b41e6;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    padding-bottom: 40px;
    .workHeadingh3 {
      color: #59f9b4;
      width: 50%;
      text-align: center;
    }

    .workText {
      width: 50%;
      padding: 0;
      div {
        .workTitle {
          list-style-type: none;
          font-size: 1.4rem;
          font-weight: 400;
          margin: 0;
          margin-top: 10px;
          color: white;
          cursor: pointer;
          .knowMoreIcon {
            position: relative;
            top: 10px;
          }
        }
      }
    }
  }
}

.containerWork {
  color: white;
  margin: 0;
  padding: 0;

  .workInfo {
    list-style-type: none;
    font-style: italic;
  }

  .workMoreText {
    list-style-type: none;
    color: #bcffe2;
  }
}

@media (min-width: 600px) and (max-width: 900px) {
  .workexperienceContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;

    .workexperience {
      width: 80%;
      box-sizing: border-box;
      .workHeadingh3 {
        font-size: 1.2rem;
        width: 40%;
      }

      .workText {
        margin-top: -10px;
        width: 60%;
        div {
          .workTitle {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .workexperienceContainer {
    .workexperience {
      flex-direction: column;
      width: 90%;
      box-sizing: border-box;
      justify-content: center;
      align-items: center;
      .workHeadingh3 {
        font-size: 1.1rem;
        text-align: center;
      }

      .workText {
        width: 90%;
        font-size: 0.8rem;

        div {
          .workTitle {
            font-size: 0.9rem;
            .knowMoreIcon {
              width: 30px;
              height: 30px;
            }
          }
        }
      }
    }

    .containerWork {
      font-size: 0.8rem;

      .workInfo {
        font-size: 0.8rem;
        list-style-type: none;
      }

      .workMoreText {
        font-size: 0.8rem;
      }
    }
  }
}
