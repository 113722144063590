.aboutmeContainer {
    width:63%;
    height:fit-content;
    background-color:#394B41E6;
    border-radius: 5px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom:50px;
    margin-top:50px;
    .bullseye {
     width: 50%;
     display: flex;
     justify-content: center;
     align-items: center;
     .bulleyeimg {
        margin-top: -40px;
        margin-bottom: -40px;
     }
      
    }
    .aboutmeQuote {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        p{
        color:white;
            width: 70%;
        font-size: 1.2rem;
        line-height: 40px;
        text-align: center;
    }
    }
}
@media (min-width:600px) and (max-width:900px) {

    .aboutmeContainer {
        width:80%;
  
        .bullseye {

}}}

@media screen and (max-width:600px) {
    .aboutmeContainer {
        flex-direction: column;
        width: 90%;
        .bullseye {
            
            .bulleyeimg {
                width:300px; 
            }

        }

        .aboutmeQuote {
            width: 90%;
            margin-top: 50px;
      
            p{
      
                width: 100%;
            font-size: 0.9rem;
            line-height: 30px;
      
        }
    }
    }
}